import React, { useEffect, useState, useCallback } from "react"
import * as styles from "./list.module.css"
import Pagenation from "components/tools/pagination.js"
import Layout from "components/common/layout.js"
import { navigate } from "gatsby-link"
import { getDateYMD, getThumb } from "utils"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getExhibitionListAsync } from "store/modules/exhibitionReducer.js"
import Menu from "components/class/room/menu.js"
import OrderMenu from "components/tools/orderMenu"
import queryString from "query-string"
const List = props => {
  const dispatch = useDispatch()
  const getExhibitionList = useCallback(
    (currentPage, pagePostSize, order) =>
      dispatch(getExhibitionListAsync(currentPage, pagePostSize, order)),
    [dispatch]
  )

  const { exhibitionList, total } = useSelector(
    ({ exhibitionReducer }) => exhibitionReducer,
    shallowEqual
  )

  const { page } = queryString.parse(props.location.search)

  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)

  const pagePostSize = 5
  const [order, setOrder] = useState(1)

  useEffect(async () => {
    await getExhibitionList(currentPage, pagePostSize, order)
  }, [order, currentPage])

  return (
    <Layout>
      <div>
        <div className={"title"}>
          <p className={styles.subtitle}>
            피치서가 에디터들이 지금, 꼭 읽어야 하는 스토리를 추천합니다
          </p>
        </div>

        <div>
          <OrderMenu
            order={order}
            setOrder={setOrder}
            setCurrentPage={setCurrentPage}
          />
        </div>

        <div className={`colContainer ${styles.main}`}>
          {exhibitionList.map(post => (
            <Menu
              key={post.exhibit_no}
              img={getThumb(post.thumb_title_uuid)}
              title={post.title}
              text={post.introText}
              hit={post.hit}
              like={post.like}
              // selfLike={post.Like}
              date={getDateYMD(post.publishTime, "YYYY.MM.DD")}
              writer="피치서가"
              onClick={() => {
                navigate(
                  `/news/detail?exhibit_no=${post.exhibit_no}&currentPage=${currentPage}&pagePostSize=${pagePostSize}`
                )
              }}
            />
          ))}

          <div></div>
        </div>
        <div style={{ paddingTop: "50px" }}>
          <Pagenation
            total={total}
            pagePostSize={pagePostSize}
            pageSize={10}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </Layout>
  )
}

export default List
